import { useState } from 'react'
import { ActivityIndicator } from 'antd-mobile'
import useSWR from 'swr'

import { BackTop, Button, MoreOrders } from '../../components'
import { OrderItem } from '../my/orderList'
import {
  useMatchDelete,
  useQuery,
  useReachEnd,
  useRequestInfinite,
  useTitle,
  useWechatHideAllNonBaseMenuItem,
} from '../../hooks'
import emptyUrl from '../../images/empty/list.png'
import { initialState } from './search'
import './history.css'

export default function OrderHistory() {
  const [deletedIds, setDeletedIds] = useState<string[]>([])
  const matchDelete = useMatchDelete()
  const query = useQuery()
  const useMulti = query.get('use_multi') === '1' // 是否使用多账号接口
  const hasMultiAccount = localStorage.getItem('has_multi_account') === '1' // 是否是多账号用户
  const showMoreOrders = hasMultiAccount && !useMulti // 多帐号用户显示查看更多订单

  const { data: searchParams } = useSWR('/order/search', {
    fetcher: undefined,
    fallbackData: initialState,
  })
  const {
    list,
    isLoading,
    isLoadingMore,
    isReachingEnd,
    isValidating,
    loadMore,
    setSize,
    mutate,
  } = useRequestInfinite((PageIndex) => ({
    Url: useMulti
      ? 'MultiMemberOrder.MemberOrderList'
      : 'Order.MemberOrderList',
    listKey: 'list',
    Key: localStorage.getItem('c_token'),
    OrderState: 99,
    UserName: searchParams?.UserName,
    UserPhone: searchParams?.UserPhone,
    OrderCode: searchParams?.OrderCode,
    GoodsName: searchParams?.GoodsName,
    PageSize: 10,
    PageIndex,
  }))
  const renderList = list.filter(
    (item: any) => deletedIds.indexOf(item.order_id_crypto) === -1
  )

  useTitle('查询结果')
  useWechatHideAllNonBaseMenuItem()
  useReachEnd(
    () => {
      if (renderList.length === 0 || isLoadingMore || isReachingEnd) return

      loadMore()
    },
    { threshold: 250 }
  )

  function reload() {
    setSize(1)
    matchDelete(
      useMulti ? /MultiMemberOrder\.MemberOrderList/ : /Order\.MemberOrderList/
    )
    mutate()
  }

  return (
    <div className="order-history">
      {renderList.length === 0 && isValidating && (
        <ActivityIndicator text="加载中..." toast />
      )}

      {renderList.length === 0 && !isLoading && (
        <div className="order-history__empty">
          <img width="225" height="180" src={emptyUrl} alt="暂无数据" />
          {showMoreOrders ? (
            <MoreOrders
              linkProps={{ to: `/order/history?use_multi=1`, replace: true }}
            />
          ) : (
            <>
              <div className="order-history__empty__text">暂无数据</div>
              <Button type="primary" onClick={() => mutate()}>
                点击重新加载
              </Button>
            </>
          )}
        </div>
      )}

      {renderList.map((item: any) => (
        <OrderItem
          key={item.order_id_crypto}
          data={item}
          useMulti={useMulti}
          freshUpdate={reload}
          reconsiliation={(id: string) => setDeletedIds([...deletedIds, id])}
          goPay={() => (window.location.href = '/pay')}
        />
      ))}
      {renderList.length !== 0 && (
        <div className="indicator">
          {isLoadingMore && <ActivityIndicator text="加载中..." />}
          {isReachingEnd &&
            (showMoreOrders ? (
              <MoreOrders
                linkProps={{ to: `/order/history?use_multi=1`, replace: true }}
              />
            ) : (
              <span>到底咯~</span>
            ))}
        </div>
      )}

      <BackTop />
    </div>
  )
}

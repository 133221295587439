import { useEffect, useState } from 'react'
import { useLocalStorage } from 'react-use'
import type { SWRConfiguration, SWRResponse } from 'swr'
import useSWR from 'swr'

import { useWechat } from '../contexts/wechat'
import { useSearchParams } from '../hooks'
import { relogin } from '../utility/utils'

export type UseUserParams<T> = {
  getToken: (code: string) => Promise<string>
  getUser: (token?: string) => Promise<T>
  tokenKey: string
  onSuccess?: (data: T) => void
  swrOptions?: SWRConfiguration
}

export type UseUserResult<T> = SWRResponse<T> & {
  isLogin?: boolean
}

export function useUser<T = unknown>({
  tokenKey,
  getToken,
  getUser,
  onSuccess,
  swrOptions,
}: UseUserParams<T>): UseUserResult<T> {
  const { isReady } = useWechat()

  // 根据 code 获取 token
  const [searchParams, setSearchParams] = useSearchParams()
  const [code] = useState(searchParams.get('code'))
  const {
    data: token,
    error: tokenError,
    isValidating,
  } = useSWR(code ? ['token', code] : null, (_, code) => getToken(code))

  // 根据 token 获取用户信息
  const [localStorageToken, setLocalStorageToken] = useLocalStorage<string>(
    tokenKey,
    '',
    { raw: true }
  )
  const user = useSWR<T>(
    localStorageToken ? ['user', localStorageToken] : null,
    (_, token) => getUser(token),
    swrOptions
  )

  // 删除链接中的code参数
  useEffect(() => {
    if (searchParams.has('code') && (token || tokenError)) {
      searchParams.delete('code')
      searchParams.delete('state')
      setSearchParams(searchParams)
    }
  }, [searchParams, setSearchParams, token, tokenError])

  // 保存token
  useEffect(() => {
    if (token) {
      setLocalStorageToken(token)
    }
  }, [token, setLocalStorageToken])

  // 自动微信登录
  useEffect(() => {
    if (isReady && !code && !isValidating && !localStorageToken) {
      relogin()
    }
  }, [isReady, code, isValidating, localStorageToken, user.error])

  // 用户获取成功的回调
  useEffect(() => {
    if (user.data && onSuccess) {
      onSuccess(user.data)
    }
  }, [user.data, onSuccess])

  return {
    ...user,
    error: tokenError || user.error,
    isLogin: !!user.data,
  }
}

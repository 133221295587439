import { cRequest } from '../utility/http-instance'

/**
 * 根据 OgId 获取信息
 */
export function getGoodsInfo(ogId: string) {
  return cRequest.post<{ after_sale_hint?: string }>('Selection.GoodsInfo', {
    OgIdCrypto: ogId,
  })
}

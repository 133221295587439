import { lazy, ReactNode } from 'react'

import Cart from '../pages/cart/cart'
import My from '../pages/my/my'
import Categories from '../pages/list/category'
import GoodsDetail from '../pages/list/goodsDetail'
import Login from '../pages/login'
import SearchIndex from '../pages/list/searchIndex'
import SeckillList from '../pages/list/seckill'
import AddressList from '../pages/cart/addressList'
import BuyStep from '../pages/cart/buystep'
import AddressEdit from '../pages/cart/addressEdit'
import OrderList from '../pages/my/orderList'
import OrderSearch from '../pages/order/search'
import OrderHistory from '../pages/order/history'
import OrderMsg from '../pages/my/orderMsg'
import OrderDetail from '../pages/my/orderDetail'
import Logistics from '../pages/my/logistics'
import Evaluate from '../pages/list/evaluate'
import Pay from '../pages/cart/pay'
import Service from '../pages/my/service'
import AfterSaleD from '../pages/my/aftersaleDetail'
import AfterSaleList from '../pages/my/aftersaleList'
import CommentList from '../pages/my/commentList'
import PostComment from '../pages/my/postComment'
import CommentsDetail from '../pages/my/commentsDetail'
import RegStep from '../pages/register/registerStep'
import RegStepOversea from '../pages/register/registerStepOversea'
import StableIndex from '../pages/list/stableIndex'
import PackageDetail from '../pages/my/packageDetail'
import Exchange from '../pages/exchange'
import Activity from '../pages/activity'
import Download from '../pages/download'
import LianlianReceipt from '../pages/lianlian/receipt'
import Message from '../pages/message'
import Hotsale from '../pages/hotsale'
import User from '../pages/user'
import Home from '../pages/home'

import { config } from '../utility/config'

const dir = config.dir

// 根据文件路径渲染不同主体的页面
const Register = lazy(() => import(`../pages/register/${dir}/register`))
const RegisterDone = lazy(() => import(`../pages/register/${dir}/registerDone`))
const Privacy = lazy(() => import(`../pages/register/${dir}/privacy`))
const ToS = lazy(() => import(`../pages/register/${dir}/tos`))

// C端用户协议和隐私政策
const MyTos = lazy(() => import('../pages/help/tos'))
const MyPrivacy = lazy(() => import('../pages/help/privacy'))

const SearchPageNew = lazy(() => import(`../pages/list/search-new`))

// 直播分享页面
const LiveShare = lazy(() => import('../pages/live-share'))

// 分店
const BranchLogin = lazy(() => import('../pages/branch/login'))
const BranchDashboard = lazy(() => import('../pages/branch/dashboard'))
const BranchAmountSales = lazy(() => import('../pages/branch/amount/sales'))
const BranchAmountIncome = lazy(() => import('../pages/branch/amount/income'))
const BranchAmountFreeze = lazy(() => import('../pages/branch/amount/freeze'))
const BranchOrderList = lazy(() => import('../pages/branch/order/index'))
const BranchOrderDetail = lazy(() => import('../pages/branch/order/detail'))
const BranchOrderSearch = lazy(() => import('../pages/branch/order/search'))
const BranchOrderSearchResult = lazy(
  () => import('../pages/branch/order/search-result')
)
const BranchOrderLogistics = lazy(
  () => import('../pages/branch/order/logistics')
)
const BranchAftersaleChoose = lazy(
  () => import('../pages/branch/aftersale/choose')
)
const BranchAftersaleCreate = lazy(
  () => import('../pages/branch/aftersale/create')
)
const BranchAftersaleDetail = lazy(
  () => import('../pages/branch/aftersale/detail')
)
const BranchAftersaleList = lazy(
  () => import('../pages/branch/aftersale/index')
)
const BranchQrcode = lazy(() => import('../pages/branch/qrcode'))
const BranchSettings = lazy(() => import('../pages/branch/settings'))
const BranchRealnameAuthType = lazy(
  () => import('../pages/branch/realname-auth/type')
)
const BranchRealnameAuthDesc = lazy(
  () => import('../pages/branch/realname-auth/desc')
)
const BranchRealnameAuthManual = lazy(
  () => import('../pages/branch/realname-auth/manual')
)

// 门店自提
const SelfPickupStore = lazy(() => import('../pages/self-pickup/store'))

// 小程序二维码生成
const MpQrcode = lazy(() => import('../pages/mp-qrcode'))

type Route = {
  path: string
  comp: ReactNode
}

export const routes: Route[] = [
  {
    path: '/login',
    comp: Login,
  },
  {
    path: '/cart',
    comp: Cart,
  },
  {
    path: '/member',
    comp: My,
  },
  {
    path: '/search',
    comp: SearchPageNew,
  },
  {
    path: '/search-ia',
    comp: SearchPageNew,
  },
  {
    path: '/seckillList',
    comp: SeckillList,
  },
  {
    path: '/categories',
    comp: Categories,
  },
  {
    path: '/goods/register',
    comp: Register, //必须这么排匹配优先级
  },
  {
    path: '/regstep',
    comp: RegStep, //必须这么排匹配优先级
  },
  {
    path: '/regstepoversea',
    comp: RegStepOversea, //必须这么排匹配优先级
  },
  {
    path: '/regdone',
    comp: RegisterDone, //必须这么排匹配优先级
  },
  {
    path: '/goods',
    comp: GoodsDetail, //必须这么排
  },
  {
    path: '/evaluate',
    comp: Evaluate,
  },
  {
    path: '/search_index',
    comp: SearchIndex,
  },
  {
    path: '/address_list/:order_id?',
    comp: AddressList,
  },
  {
    path: '/address_edit',
    comp: AddressEdit,
  },
  {
    path: '/buy/buystep/:address_id?',
    comp: BuyStep,
  },
  {
    path: '/buy/agreement',
    comp: ToS, //必须在最后
  },
  {
    path: '/buy/privateagreement',
    comp: Privacy, //必须在最后
  },
  {
    path: '/order/msg',
    comp: OrderMsg,
  },
  {
    path: '/order/search',
    comp: OrderSearch,
  },
  {
    path: '/order/history',
    comp: OrderHistory,
  },
  {
    path: '/order',
    comp: OrderList,
  },
  {
    path: '/order_detail',
    comp: OrderDetail,
  },
  {
    path: '/pay',
    comp: Pay,
  },
  {
    path: '/service/:og_id/:num/:price',
    comp: Service,
  },
  {
    path: '/aftersale_detail',
    comp: AfterSaleD,
  },
  {
    path: '/aftersalelist',
    comp: AfterSaleList,
  },
  {
    path: '/commentlist',
    comp: CommentList,
  },
  {
    path: '/post_eva',
    comp: PostComment,
  },
  {
    path: '/comments_detail',
    comp: CommentsDetail,
  },
  {
    path: '/share',
    comp: StableIndex,
  },
  {
    path: '/logistics/:ship_code',
    comp: Logistics,
  },
  {
    path: '/package-detail/:id',
    comp: PackageDetail,
  },
  {
    path: '/exchange',
    comp: Exchange,
  },
  {
    path: '/register',
    comp: Register,
  },
  {
    path: '/activity',
    comp: Activity,
  },
  {
    path: '/download',
    comp: Download,
  },
  {
    path: '/lianlian/receipt',
    comp: LianlianReceipt,
  },
  {
    path: '/message',
    comp: Message,
  },
  {
    path: '/hotsale',
    comp: Hotsale,
  },
  {
    path: '/user',
    comp: User,
  },
  {
    path: '/help/tos',
    comp: MyTos,
  },
  {
    path: '/help/privacy',
    comp: MyPrivacy,
  },
  {
    path: '/live-share',
    comp: LiveShare,
  },

  // 分店
  { path: '/branch/login', comp: BranchLogin },
  { path: '/branch/dashboard', comp: BranchDashboard },
  { path: '/branch/amount/sales', comp: BranchAmountSales },
  { path: '/branch/amount/income', comp: BranchAmountIncome },
  { path: '/branch/amount/freeze', comp: BranchAmountFreeze },
  { path: '/branch/order/search', comp: BranchOrderSearch },
  { path: '/branch/order/search-result', comp: BranchOrderSearchResult },
  { path: '/branch/order/:id/logistics', comp: BranchOrderLogistics },
  { path: '/branch/order/:id', comp: BranchOrderDetail },
  { path: '/branch/order', comp: BranchOrderList },
  { path: '/branch/aftersale/choose', comp: BranchAftersaleChoose },
  { path: '/branch/aftersale/create', comp: BranchAftersaleCreate },
  { path: '/branch/aftersale/:id', comp: BranchAftersaleDetail },
  { path: '/branch/aftersale', comp: BranchAftersaleList },
  { path: '/branch/qrcode', comp: BranchQrcode },
  { path: '/branch/settings', comp: BranchSettings },
  { path: '/branch/realname-auth/type', comp: BranchRealnameAuthType },
  { path: '/branch/realname-auth/desc', comp: BranchRealnameAuthDesc },
  { path: '/branch/realname-auth/manual', comp: BranchRealnameAuthManual },

  // 门店自提
  { path: '/self-pickup/store', comp: SelfPickupStore },

  // 小程序二维码生成
  { path: '/mp-qrcode', comp: MpQrcode },

  //必须在最后
  { path: '/', comp: Home },
]

import { ActivityIndicator } from 'antd-mobile'
import useSWRImmutable from 'swr/immutable'

import { ErrorBlock } from '../components'
import { useShopId } from '../hooks'
import { getWxqrcodeHome } from '../api'
import { config } from '../utility/config'

export default function Login() {
  const { shopId, branchId } = useShopId()
  const { data, error } = useSWRImmutable(
    shopId ? ['Home.GetWxqrcodeHome', shopId, branchId] : null,
    (_, shopId, branchId) => getWxqrcodeHome(shopId, branchId)
  )

  // 没有店铺id或者请求出错
  if (!shopId || error) {
    return (
      <ErrorBlock
        desc="获取店铺信息失败，请联系店主获取最新店铺链接"
        fullPage
      />
    )
  }

  if (!data) {
    return <ActivityIndicator text="加载中" toast />
  }

  return (
    <div style={{ textAlign: 'center' }}>
      <div className="login-top">
        <h3>{config.title}</h3>
        <div className="login-box">
          <img src={data} alt="mini" />
        </div>
        <div className="login-tip">识别二维码打开小程序</div>
      </div>
    </div>
  )
}

import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import useSWRImmutable from 'swr/immutable'
import { Toast } from 'antd-mobile'

import { useQuery } from './useQuery'
import { wxLogin } from '../api'
import store from '../redux/store'

/**
 * 微信登录
 */
export function useToken() {
  const history = useHistory()
  const location = useLocation()
  const query = useQuery()
  const code = query.get('code')

  const [token, setToken] = useState(localStorage.getItem('c_token') || '')
  const { data, error } = useSWRImmutable(
    code ? ['Home.GetWxinfo', code] : null,
    (_, code) => wxLogin(code)
  )

  // 登录报错
  useEffect(() => {
    if (error) {
      Toast.info(error.response?.msg || '登录失败，请重试')
    }
  }, [error])

  useEffect(() => {
    if (!data && !error) return

    const searchParams = new URLSearchParams(location.search)

    if (data) {
      // 本地缓存用户 token
      searchParams.delete('force_login')
      localStorage.setItem('c_token', data.token)
      store.dispatch({ type: 'LOGIN' })
      setToken(data.token)
    }

    // 移除 url 中的 code 参数防止二次使用
    if (searchParams.has('code')) {
      searchParams.delete('code')
      searchParams.delete('state')

      history.replace({
        search: searchParams.toString(),
      })
    }
  }, [data, error, history, location.search])

  return [token, code, !!data] as const
}

import { useState, useEffect, useCallback } from 'react'
import { Link, useHistory } from 'react-router-dom'
import {
  Checkbox,
  WhiteSpace,
  Toast,
  ActivityIndicator,
  SearchBar,
  Modal,
} from 'antd-mobile'
import { Icon } from 'antd'
import qs from 'qs'

import { Button, FloatBottom } from '../../components'
import { request, reLogin } from '../../utility/request'
import { showConfirm } from '../../utility/modal'
import store from '../../redux/store'
import './address.css'

const AddressList = (props) => {
  const {
    match: {
      params: { order_id = null },
    },
  } = props
  const key = localStorage.getItem('c_token') || ''
  const [addressList, setAddressList] = useState([])
  const [force, setForce] = useState(24)
  const [keyword, setKeyword] = useState('')
  const [loading, setLoading] = useState(true)
  const [wechatReady, setWechatReady] = useState(false)
  const skipT = parseInt(localStorage.getItem('skip_add')) || null

  const history = useHistory()

  function goAddAddress() {
    history.push('/address_edit?a_id=0')
  }

  function importAddress() {
    if (!wechatReady) {
      Toast.info('请稍候或刷新页面重试')
      return
    }

    window.wx.openAddress?.({
      success: (res) => {
        store.dispatch({
          type: 'SET_WECHAT_ADDRESS',
          payload: res,
        })
        goAddAddress()
      },
    })
  }

  useEffect(() => {
    window.wx.ready(function () {
      window.wx.hideOptionMenu()
      setWechatReady(true)
    })
  }, [])

  useEffect(() => {
    request({
      url: 'Buy.AddressList',
      method: 'POST',
      body: qs.stringify({
        Key: key,
        keyword,
      }),
    }).then(function (res) {
      if (res.flag === 200) {
        setAddressList(res.data)
        setLoading(false)
      } else if (res.flag === 401) {
        reLogin(() => {
          props.history.push(
            `/login?shop_id=${localStorage.getItem(
              'shop_id'
            )}&branch_id=${localStorage.getItem('branch_id')}`
          )
        })
      } else {
        res.msg && Toast.fail(res.msg)
      }
    })
  }, [key, force, props.history, keyword])

  const handleUpdate = () => {
    setForce((n) => n + 2)
  }
  return (
    <div
      style={{ minHeight: '100vh' }}
      className={!addressList ? 'white-background' : 'grey-background'}
    >
      <div>
        <SearchBar
          placeholder="请输入收货人手机号或姓名"
          maxLength={13}
          onSubmit={(v) => setKeyword(v)}
        />
      </div>
      {order_id && (
        <div className="top-change-tip">
          <p>每笔订单仅允许修改一次收货地址</p>
          <p>新的收货地址只能选择运费与原地址相同的地区</p>
        </div>
      )}

      {loading && (
        <div className="loading-example">
          <ActivityIndicator animating />
        </div>
      )}
      {!loading && !addressList && (
        <div style={{ paddingTop: '40%', textAlign: 'center' }}>
          <img
            alt=""
            src={require('../../images/cart/no-address.png').default}
            style={{ width: 80 }}
          />
          <h3 style={{ color: '#999999', marginTop: 20, fontSize: 14 }}>
            还没有收货地址哦，添加收货地址下单更方便~
          </h3>
        </div>
      )}

      {!loading &&
        addressList &&
        addressList.map((item) => (
          <AddressItem
            data={item}
            key={item.address_id}
            handleUpdate={handleUpdate}
            skipT={skipT}
            order_id={order_id}
            token={key}
            {...props}
          />
        ))}

      <FloatBottom>
        <div className="address-bottom-buttons">
          <Button size="large" isFull onClick={goAddAddress}>
            添加新地址
          </Button>
          <Button
            size="large"
            isFull
            style={{ backgroundColor: '#1AAD19' }}
            onClick={importAddress}
          >
            <Icon type="wechat" style={{ marginRight: 4 }} />
            微信导入
          </Button>
        </div>
      </FloatBottom>
    </div>
  )
}

const AddressItem = (props) => {
  const { data, skipT, order_id, token } = props
  const AddressID = data.address_id

  const setDefault = useCallback(() => {
    request({
      url: 'Buy.SetDefaultAddress',
      method: 'POST',
      body: qs.stringify({
        Key: token,
        AddressID,
      }),
    }).then(function (res) {
      if (res.flag === 200) {
        Toast.success('', 1, () => props.handleUpdate())
      } else if (res.flag === 401) {
        reLogin(() =>
          props.history.push(
            `/login?shop_id=${localStorage.getItem(
              'shop_id'
            )}&branch_id=${localStorage.getItem('branch_id')}`
          )
        )
      } else {
        res.msg && Toast.fail(res.msg)
      }
    })
  }, [token, AddressID])

  const handleAddDelete = useCallback(
    () =>
      showConfirm('', '确定要删除吗？', () => {
        request({
          url: 'Buy.DelAddress',
          method: 'POST',
          body: qs.stringify({
            Key: token,
            AddressID,
          }),
        }).then(function (res) {
          if (res.flag === 200) {
            Toast.success('', 1, () => props.handleUpdate())
          } else if (res.flag === 401) {
            reLogin(() =>
              props.history.push(
                `/login?shop_id=${localStorage.getItem(
                  'shop_id'
                )}&branch_id=${localStorage.getItem('branch_id')}`
              )
            )
          } else {
            res.msg && Toast.fail(res.msg)
          }
        })
      }),
    [token, AddressID]
  )

  const changeOrderAddress = (ignoreAddrWords = 0) => {
    request({
      url: 'Order.ChangeOrderAddress',
      method: 'POST',
      body: qs.stringify({
        Key: token,
        AddressID,
        OrderIdCrypto: order_id,
        IgnoreAddrWords: ignoreAddrWords,
      }),
    }).then(function (res) {
      if (res.flag === 200) {
        Toast.success('成功', 1, () => props.history.goBack())
      } else if (res.flag === 410) {
        Modal.alert('', res.msg, [
          { text: '暂不修改', onPress: () => changeOrderAddress(1) },
          {
            text: '修改地址',
            onPress: () =>
              props.history.push(`/address_edit?a_id=${AddressID}`),
          },
        ])
      } else {
        res.msg && Toast.fail(res.msg)
      }
    })
  }

  const historyBack = () => {
    if (order_id) {
      showConfirm('', '每个订单只能修改一次地址，确定要更改地址吗？', () => {
        changeOrderAddress(0)
      })
    } else {
      localStorage.setItem('addressid', AddressID)
      props.history.replace('/buy/buystep/' + AddressID)
    }
  }

  return (
    <>
      <div className="ad-wrap">
        <div className="ad-top">
          {order_id && (
            <div onClick={historyBack}>
              <div className="ad-top-info">
                <p>{data.full_name}</p>
                <p>{data.telphone}</p>
              </div>
              <p style={{ fontSize: 14 }}>
                收货地址：{data.area_info} {data.address}
              </p>
            </div>
          )}

          {!order_id && skipT === 6 && (
            <div onClick={historyBack}>
              <div className="ad-top-info">
                <p>{data.full_name}</p>
                <p>{data.telphone}</p>
              </div>
              <p style={{ fontSize: 14 }}>
                收货地址：{data.area_info} {data.address}
              </p>
            </div>
          )}

          {!order_id && skipT !== 6 && (
            <>
              <div className="ad-top-info">
                <p>{data.full_name}</p>
                <p>{data.telphone}</p>
              </div>
              <p style={{ fontSize: 14 }}>
                收货地址：{data.area_info} {data.address}
              </p>
            </>
          )}
        </div>
        <div className="ad-bot">
          <Checkbox
            className="cbox"
            checked={parseInt(data.is_default) === 1 ? true : false}
            onChange={setDefault}
          >
            默认地址
          </Checkbox>
          <div className="handle-address">
            <Link to={'/address_edit?a_id=' + data.address_id}>
              <div>
                <Icon type="edit" />
                编辑
              </div>
            </Link>
            <div style={{ marginLeft: 15 }} onClick={handleAddDelete}>
              <Icon type="delete" />
              <span>删除</span>
            </div>
          </div>
        </div>
      </div>
      <WhiteSpace style={{ height: 12, backgroundColor: '#F4F4F4' }} />
    </>
  )
}

export default AddressList
